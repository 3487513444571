<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('externalTradeTraiff.appointment_history') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="4" md="4" sm="4" xs="12">
                  <ValidationProvider name="Organization" vid="org_id">
                    <b-form-group
                      label-for="org_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('externalTradeTraiff.office')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      disabled
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col lg="4" md="4" sm="4" xs="12">
                  <ValidationProvider name="Office Type" vid="office_type_id">
                    <b-form-group
                      label-for="office_type_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('license_management.office_type')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.office_type_id"
                      :options="officeTypeList"
                      id="office_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col lg="4" md="4" sm="4" xs="12">
                  <ValidationProvider name="Office" vid="office_id">
                    <b-form-group
                      label-for="office_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('globalTrans.office')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.office_id"
                      :options="officeList"
                      id="office_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col lg="4" md="4" sm="4" xs="12">
                  <ValidationProvider name="Designation" vid="designation_id">
                    <b-form-group
                      label-for="designation_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('bazarMonitoring.designation')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.designation_id"
                      :options="designationList"
                      id="designation_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col sm="3">
                  <b-button size="sm" type="submit" variant="primary" class="mt-20">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTradeTraiff.appointment_history') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper">
                <div class="quick-filter-left">
                  <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                    <b-dropdown-form>
                      <div
                        class="form-group"
                        v-for="(field, index) in labelData"
                        :key="index"
                        >
                        <b-form-checkbox
                          :id="'checkbox-' + field.label_en"
                          v-model="field.show"
                          :name="'checkbox-' + field.label_en"
                          value=1
                          unchecked-value=0
                        >
                          {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span v-if="data.item.status === 0"><b-badge pill variant="warning">{{ $t('globalTrans.pending')}}</b-badge></span>
                    <span v-if="data.item.status === 1"><b-badge pill variant="info">{{ $t('globalTrans.processing')}}</b-badge></span>
                    <span v-if="data.item.status === 2"><b-badge pill variant="success">{{ $t('externalTradeTraiff.approve')}}</b-badge></span>
                    <span v-if="data.item.status === 3"><b-badge pill variant="primary">{{ $t('globalTrans.complete')}}</b-badge></span>
                    <span v-if="data.item.status === 4"><b-badge pill variant="danger">{{ $t('externalTradeTraiff.cancel')}}</b-badge></span>
                    <span v-if="data.item.status === 5"><b-badge pill variant="danger">{{ $t('externalTradeTraiff.reject')}}</b-badge></span>
                    <span v-if="data.item.status === 6"><b-badge pill variant="danger">{{ $t('externalTradeTraiff.end')}}</b-badge></span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- <b-button v-if="data.item.profile_status === 0" v-b-modal.modal-form variant="iq-bg-success mr-1" size="sm" @click="concernOfficer(data.item)" class="action-btn edit" :title="$t('concernManagement.profile_create')"><i class="ri-pencil-line"></i></b-button> -->
                    <b-button v-if="data.item.status === 0 || data.item.status === 2 || data.item.status === 3 || data.item.status === 4 | data.item.status === 6" v-b-modal.modal-details variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn edit" :title="$t('externalTradeTraiff.request_for_consultation')"><i class="ri-eye-fill"></i></b-button>
                    <span v-if="data.item.status === 1"><b-badge pill variant="success">{{ $t('externalTradeTraiff.request_for_appointment')}}</b-badge></span>
                    <span v-if="data.item.status === 5"><b-badge pill variant="info">{{ $t('externalTradeTraiff.no_avaliable')}}</b-badge></span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import Details from './Details.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { appointmentHistoryList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import i18n from '@/i18n'

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.sl_no, label_bn: i18n.messages.bn.globalTrans.sl_no, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
  { label_en: i18n.messages.en.externalTradeTraiff.office, label_bn: i18n.messages.bn.externalTradeTraiff.office, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
  { label_en: i18n.messages.en.license_management.office_type, label_bn: i18n.messages.bn.license_management.office_type, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.globalTrans.office, label_bn: i18n.messages.bn.globalTrans.office, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.organogram.designation, label_bn: i18n.messages.bn.organogram.designation, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.externalTradeTraiff.subject, label_bn: i18n.messages.bn.externalTradeTraiff.subject, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.globalTrans.status, label_bn: i18n.messages.bn.globalTrans.status, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '15%' } },
  { label_en: i18n.messages.en.globalTrans.action, label_bn: i18n.messages.bn.globalTrans.action, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } }
]

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        org_id: 4,
        office_type_id: 0,
        office_id: 0,
        designation_id: 0,
        employee_id: 0,
        user_id: this.$store.state.Auth.authUser.user_id,
        limit: 20
      },
      items: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      userList: [],
      userLoading: false,
      viewitemId: 0,
      actions: {
        forward: false,
        edit: false,
        details: true,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
    this.officeTypeList = this.getOfficeTypeList(4)
     this.labelData = this.labelList
  },
  computed: {
    orgList () {
      const list = this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.value === 4)
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    labelList: function () {
      const dataList = defaultColumn
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          const data = { value: item.label_en, text: item.label_bn }
          return Object.assign({}, item, data)
        } else {
          const data = { value: item.label_en, text: item.label_en }
          return Object.assign({}, item, data)
        }
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        const labelData = {}
        labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
        return Object.assign(item, labelData)
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'org_name_bn' },
          { key: 'office_type_name_bn' },
          { key: 'office_name_bn' },
          { key: 'desingation_name_bn' },
          { key: 'subject_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'org_name' },
          { key: 'office_type_name' },
          { key: 'office_name' },
          { key: 'desingation_name' },
          { key: 'subject_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('concernManagement.concern_officer_profile') + ' ' + this.$t('globalTrans.entry') : this.$t('concernManagement.concern_officer_profile') + ' ' + this.$t('globalTrans.modify')
    },
    viewTitle () {
      return this.$t('concernManagement.concern_officer_profile') + ' ' + this.$t('globalTrans.details')
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    }
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      } else {
        this.officeList = []
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      } else {
        this.designationList = []
      }
    },
    currentLocale: function () {
      if (this.search.org_id) {
        this.officeTypeList = this.getOfficeTypeList(this.search.org_id)
      }
      if (this.search.office_type_id) {
        this.officeList = this.getOfficeList(this.search.office_type_id)
      }
      if (this.search.office_id) {
        this.designationList = this.getDesignationList(this.search.office_id)
      }
    }
  },
  methods: {
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === officeTypeId)
      return officeList
    },
    getDesignationList (officeId) {
      const list = this.$store.state.CommonService.commonObj.assignDesignationList.filter(item => item.office_id === officeId && item.office_type_id === this.search.office_type_id && item.org_id === this.search.org_id)
      return list.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    concernOfficer (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.items = item
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, appointmentHistoryList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(org => org.value === parseInt(item.officer.org_id))
          const officeTypebj = this.$store.state.CommonService.commonObj.officeTypeList.find(type => type.value === parseInt(item.officer.office_type_id))
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(type => type.value === parseInt(item.officer.office_id))
          const desingationObj = this.$store.state.CommonService.commonObj.designationList.find(desingation => desingation.value === parseInt(item.officer.designation_id))
          const subjectObj = this.$store.state.ExternalUserService.tradeTariff.commonObj.subjectList.find(sub => sub.value === parseInt(item.officer.subject_id))
          const orgData = {}
          const officeTypeData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org_name = orgObj.text_en
            orgData.org_name_bn = orgObj.text_bn
          } else {
            orgData.org_name = ''
            orgData.org_name_bn = ''
          }
          if (typeof officeTypebj !== 'undefined') {
            officeTypeData.office_type_name = officeTypebj.text_en
            officeTypeData.office_type_name_bn = officeTypebj.text_bn
          } else {
            officeTypeData.office_type_name = ''
            officeTypeData.office_type_name_bn = ''
          }
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office_name = officeObj.text_en
            officeData.office_name_bn = officeObj.text_bn
          } else {
            officeData.office_name = ''
            officeData.office_name_bn = ''
          }
          const designationData = {}
          if (typeof desingationObj !== 'undefined') {
            designationData.desingation_name = desingationObj.text_en
            designationData.desingation_name_bn = desingationObj.text_bn
          } else {
            designationData.desination_name = ''
            designationData.desingation_name_bn = ''
          }
          const subjectData = {}
          if (typeof subjectObj !== 'undefined') {
            subjectData.subject_en = subjectObj.text_en
            subjectData.subject_bn = subjectObj.text_bn
          } else {
            subjectData.subject_en = ''
            subjectData.subject_bn = ''
          }
          return Object.assign({}, item, { serial: index }, orgData, officeTypeData, officeData, designationData, subjectData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
